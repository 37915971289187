@import "../../assets/scss/variables.scss";

.faucet-card {
  background: $background-light;
  padding: 1px;
  border-radius: $base-border-radius;
  box-shadow: $box-shadow-inner-light;
  border: 1px solid transparent;
  padding: 3rem 5rem;
  max-width: 900px;
  width: 100%;
  margin: auto;
  text-align: center;
  @media screen and (max-width: 767px) {
    padding: 2.5rem 2rem;
  }
  .dark-mode & {
    background: $background-dark1;
    border: 1px solid $border-dark;
    box-shadow: $box-shadow-inner-dark;
  }
  h1 {
    font-size: 2.6rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    flex-wrap: wrap;
  }
  p {
    font-size: 1.3rem;
  }
  .ant-input {
    height: 64px;
    border-radius: 15px;
    border: 0;
    font-size: 1.3rem;
    padding: 0 20px;
    box-shadow: $box-shadow-inner-light;

    .dark-mode & {
      background: $background-dark2;
      color: $white;
      box-shadow: $box-shadow-input-dark;
      &::placeholder {
        color: $text-color-label-dark2;
      }
      &::-webkit-placeholder {
        color: $white;
      }
      &::-moz-placeholder {
        color: $white;
      }
    }
  }
  .ant-btn {
    font-size: $font-size-lg + 2;
    height: 48px;
    line-height: 48px;
    padding: 0 40px;
    border-radius: 50px;
  }
}

.ant-select {
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      height: 40px;
      line-height: 40px;
      background-color: #0f3a79;
      // background: $secondary-color;
      background: transparent;
      color: white;
      border: 1px solid $secondary-color;
      border-radius: 6px;

      &:hover,
      &:focus {
        border: 1px solid $secondary-color;
      }
      input {
        height: 40px !important;
        line-height: 100%;
      }
      .ant-select-selection-item {
        line-height: 38px;
      }
      .ant-select-selection-placeholder {
        line-height: 38px;
        color: white;
      }
    }
    .ant-select-arrow {
      color: white;
      .icon {
        width: 13px;
      }
    }
  }
  &:hover,
  &:focus {
    .ant-select-selector {
      border-color: $secondary-color !important;
    }
  }
}
