/*
*
*                                                           
*/
@import "./variables.scss";

html,
body {
  min-height: 100vh;
  font-family: $font-family;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  color: $text-color-dark;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  font-weight: 300;
  background: $body-background-light;
  height: auto !important;
  margin: 0;
  padding: 0;

  &::before {
    background: $sidebar-bg-light;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  &.dark-mode {
    background: $body-background-dark;
    color: $white;

    &::before {
      background: radial-gradient(66.64% 66.79% at 3.9% 4.23%,
          #16375c 0%,
          #040516 100%);
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &::after {
      background: radial-gradient(66.64% 66.79% at 113.9% 104.23%,
          rgba(254, 67, 80, 0.6) 0.56%,
          rgba(4, 5, 23, 0) 96.15%);
      content: "";
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}

body.sidebar-open {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

label,
button {
  user-select: none;
}

.icon {
  width: 16px;
  height: auto;
  fill: $text-color-dark;
  transition: fill 0.3s;

  &.dark-mode {
    fill: $text-color-light;
  }

  &.icon-white {
    fill: $text-color-light;
  }
}

.mt-15px {
  margin-top: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.main_wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  align-items: flex-start;

  .main-container {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 1 100%;
    position: relative;
    z-index: 1;

    .content {
      max-width: 100%;
      padding: 60px 100px 0;
      margin: 0 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 0 1 100%;
      transition: none;

      @media screen and (max-width: 1120px) {
        padding-left: 15px;
        padding-right: 15px;
      }

      @media screen and (max-width: 991px) {
        max-width: 100%;
      }

      @media only screen and (max-width: 767px) {
        padding: 20px 15px 0;
      }

      .app-content-wrapper {
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        flex: 0 1 100%;
        position: relative;
        z-index: 2;

        .app-content-small {
          width: 100%;
          max-width: 540px;
          margin: auto;
        }
      }
    }
  }
}


.ant-btn {
  border: 0;
  font-size: $font-size-base;
  padding: 0 22px;
  color: $white;
  background: $btn-bg-dark;
  border-radius: $base-border-radius;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  height: 36px;
  line-height: 36px;
  font-weight: normal;

  &:not(.ant-btn-round) {
    border-radius: 0;
  }

  a {
    color: $text-color-dark2;

    .dark-mode & {
      color: $white;
    }
  }

  .icon {
    fill: $text-color-dark2;

    .dark-mode & {
      fill: $white;
    }
  }

  &.ant-btn-primary,
  &.ant-btn-primary-success {
    cursor: pointer;
    background-color: transparent;
    margin: 0 auto;
    color: $text-color-dark2;
    border-radius: 20px;
    border: 1px solid $secondary-color;
    transition: all 300ms ease-in-out;

    .dark-mode & {
      color: $white;
    }

    &:hover:not(&[disabled]),
    &:active,
    &:focus {
      background-color: $secondary-color;
      color: $white;
      border-color: $secondary-color;
    }

    &[disabled] {
      opacity: 0.7;
      cursor: not-allowed;
      border-color: $secondary-color;
      background-color: $secondary-color;
    }

    &.btn-filled {
      background-color: $secondary-color;
      color: $white;

      &:hover:not(&[disabled]),
      &:active,
      &:focus {
        background-color: $secondary-color-hover;
        color: $white;
        border-color: $secondary-color-hover;
      }

      &[disabled] {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }

  &.ant-btn-secondary {
    background: $secondary-btn-color;
    border-color: $secondary-btn-color;
    color: $white;
    border-radius: 40px;

    &:hover,
    &:active,
    &:focus {
      background: rgba($secondary-btn-color, 0.7);
      border-color: rgba($secondary-btn-color, 0.7);
      color: $white;
    }
  }

  &.ant-btn-sm {
    height: 28px;
    padding: 0 15px;
    line-height: 100%;
    font-size: $font-size-sm - 1;
  }

  &.ant-btn-lg {
    padding: 0 22px;
    height: 42px;
    line-height: 42px;
    font-size: $font-size-lg;
  }

  &.success-btn {
    background: $success-color;
    border-color: $success-color;
    color: $white;
    padding: 0 15px;

    &:hover,
    &:focus {
      background: $success-color;
      border-color: $success-color;
      color: $white;
      opacity: 0.8;
    }
  }

  &.dark-btn {
    background: #232726;
    color: $white;
    border-color: #232726;

    &:hover,
    &:focus {
      background: #232726;
      color: $white;
      border-color: #232726;
      opacity: 0.8;
    }
  }
}



.ant-modal {
  .ant-modal-close {
    top: 12px;
    right: 12px;
    position: absolute;

    .ant-modal-close-x {
      width: 26px;
      height: 26px;
      line-height: 26px;
    }

    .icon {
      fill: $secondary-color;
      width: 12px;

      .dark-mode & {
        fill: $white;
      }
    }
  }

  .ant-modal-header {
    background: none;
    border: 0;

    .ant-modal-title {
      font-size: 1.3rem;
      font-weight: 300;

      .dark-mode & {
        color: $white;
      }
    }
  }

  .ant-modal-content {
    border-radius: 15px;
    padding: 50px 30px;
    background: $background-light;
    box-shadow: $box-shadow-inner-light;

    .dark-mode & {
      background: $background-dark1;
      box-shadow: $box-shadow-inner-dark;
      border: 2px solid $border-dark;
    }

    .modal-btn {
      height: 38px;
      line-height: 38px;
      padding: 0 55px;
    }
  }
}

.ant-modal-mask {
  .dark-mode & {
    background: rgba(0, 0, 0, 0.8);
  }
}

.ant-form {
  .ant-form-item {
    .ant-form-item-label {
      width: 50%;

      label {
        .dark-mode & {
          color: $text-color-label-dark;
        }
      }
    }

    .ant-form-item-control {
      .ant-input {
        height: 50px;
        border-radius: 15px;
        border: 0;

        .dark-mode & {
          background: $background-dark2;
          color: $white;
          box-shadow: $box-shadow-input-dark;

          &::placeholder {
            color: $white;
          }

          &::-webkit-placeholder {
            color: $white;
          }

          &::-moz-placeholder {
            color: $white;
          }
        }
      }
    }
  }
}



.ant-input {
  &.input-cmdx {
    text-align: right;

    .dark-mode & {
      background: $background-input-dark;
      border-color: $border-input-light;
      color: $text-color-label-dark;
    }
  }

  &::placeholder {
    color: $text-color-dark2;

    .dark-mode & {
      color: white;
    }
  }
}



::-webkit-scrollbar {
  width: 4.2px;
  height: 4.2px;
  background: $background-light;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $secondary-color;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: none;
}

.dropconnect-overlay {
  padding: 0;

  .ant-dropdown-menu {
    padding: 0;
    background: none;

    .ant-dropdown-menu-item {
      padding: 0;
      cursor: default;
      background: none !important;
    }
  }
}